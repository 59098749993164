<template>
  <BaseModal :config="config">
    <template #content>
      <div class="modal-login">
        <div class="form-header">
          <div v-if="showBack" class="modal-back" @click="router.back()">
            <span class="icon-calendar-arrow-left" />
          </div>
          <p class="title">{{ titleModal }}</p>
          <div class="modal-close" @click="onHideModal()">
            <span class="icon-close-circle-fill" />
          </div>
        </div>
        <div class="tab-content">
          <FormLogin v-if="tabActive === MODAL_TYPES.LOGIN" :is-show-modal="isShowModal" :tab-active="tabActive" />
          <FormRegister
            v-if="tabActive === MODAL_TYPES.REGISTER"
            :is-show-modal="isShowModal"
            :tab-active="tabActive"
          />
          <FormForgotPassword v-if="tabActive === MODAL_TYPES.FORGOT_PASSWORD" />
          <FormResetPassword v-if="tabActive === MODAL_TYPES.RESET_PASSWORD" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { LocationQueryValue } from 'vue-router'
import BaseModal from '~/components/common/base-modal.vue'
import FormLogin from '~/components/common/form/login.vue'
import FormRegister from '~/components/common/form/register.vue'
import FormForgotPassword from '~/components/common/form/forgot-password.vue'
import FormResetPassword from '~/components/common/form/reset-password.vue'
import { MODAL_TYPES } from '~/config/constant'
import { useAppStore } from '~/store/app'
import useModal from '~/composables/useModal'
import { ModalConfig } from '~/types/modal.type'
import BaseImg from '~/components/common/base-img.vue'

const router = useRouter()
const route = useRoute()
const { closeModal, openModal } = useModal()
const tabActive = ref<string | LocationQueryValue[]>(MODAL_TYPES.LOGIN)
const { $pinia } = useNuxtApp()
const { $device } = useNuxtApp()

const isMobile = $device.isMobile
const config: ModalConfig = {
  id: MODAL_TYPES.LOGIN,
  centered: isMobile,
  hideClose: true,
  fullscreen: false,
  iconClose: 'icon-close-circle-fill',
  textBack: 'Back',
  noCloseOnBackdrop: true
}
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const isShowModal = ref(false)

const titleModal = computed(() => {
  switch (tabActive.value) {
    case MODAL_TYPES.LOGIN:
      return 'Đăng Nhập'
    case MODAL_TYPES.REGISTER:
      return 'Tạo Tài Khoản'
    case MODAL_TYPES.FORGOT_PASSWORD:
      return 'Quên Mật Khẩu'
    case MODAL_TYPES.RESET_PASSWORD:
      return 'Đổi Mật Khẩu'
    default:
      return ''
  }
})

const showBack = computed(() => {
  return tabActive.value === MODAL_TYPES.FORGOT_PASSWORD
})

watch(route, () => {
  if (route.query.popup) {
    tabActive.value = route.query.popup
  } else {
    closeModal(MODAL_TYPES.LOGIN)
  }
})

onMounted(() => {
  if (
    (route.query.popup === MODAL_TYPES.LOGIN ||
      route.query.popup === MODAL_TYPES.REGISTER ||
      route.query.popup === MODAL_TYPES.FORGOT_PASSWORD ||
      route.query.popup === MODAL_TYPES.RESET_PASSWORD) &&
    !isLogged.value
  ) {
    tabActive.value = route.query.popup
    nextTick(() => {
      setTimeout(() => {
        openModal(MODAL_TYPES.LOGIN)
      }, 200)
    })
  }
})

const onHideModal = () => {
  isShowModal.value = false

  if (route.query.popup === MODAL_TYPES.RESET_PASSWORD) {
    navigateTo('/')
    return
  }

  navigateTo({
    path: location.pathname,
    query: { ...route.query, popup: undefined, openUrl: undefined, navigateUrl: undefined }
  })
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/modals/login/index.scss" />
